<template>
  <div class="app-contianer AppDownload">
    <div class="download-but" @click="checkSys()">
      <a :href="downloadUrl">{{osType === 'ANDROID' ? 'Android':'IOS'}}下载</a>
    </div>
  </div>
</template>

<script>
import { judgeTerrace } from '../../utils/utils'
import { mallApi } from '@/api'
import { getUrlParams } from '@/utils/utils'


export default {
  name: 'AppDownload',
  data() {
    return {
      downloadUrl: '',
      osType: 'ANDROID'
    }
  },
  created() {
    let ran = navigator.userAgent
    this.isAndroid = ran.indexOf('Android') > -1 || ran.indexOf('Linux') > -1
    this.isIOS = !!ran.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    if (this.isIOS) {
      this.osType = 'IOS'
    }
    // console.log(this.isIOS);
    // this.osType = 'IOS'

    this.getAppInfo()
  },
  methods: {
    checkSys() {
      let ismobileWeiXin = judgeTerrace()
      if (ismobileWeiXin) {
        this.$toast('请复制链接,使用手机浏览器打开')
      }
    },
    getAppInfo() {
      let url = location.hrefs
      const urlParams = getUrlParams(url)

      let params = {
        trxCode: 'XF916',
        agentId: urlParams.agentId,
        osType: this.osType
      }
      mallApi(params).then(res => {
        if (res.data.appUrl) {
          this.downloadUrl = res.data.appUrl
        } else {
          this.$toast('暂无该版本, 敬请期待~')
        }
      })
    }
  }
}
</script>

<style lang='less' scoped>
.AppDownload {
  position: relative;
  background-image: url('../../assets/app/download_bg.png');
  background-size: 100% 100%;
}
.download-but {
  margin-top: 60vh;
  text-align: center;

  a {
    padding: 12px 32px;
    border: 1px solid #fff;
    color: #fff;
    letter-spacing: 5px;
    font-size: 18px;
    border-radius: 999px;
  }
}
</style>